<template>
  <main class="layout-main">
    <div class="inner">
      <div class="container-wrapper">
        <div class="layout-title">
          <h1 class="heading-page"><img src="@/assets/image/title/404.svg" alt="Contact"></h1>
        </div>
        <section>
          <div class="image-404"><img src="@/assets/image/404/image.jpg" alt=""></div>
          <p class="_center code-404">お探しのページは見つかりませんでした。</p>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, onUpdated, onUnmounted } from 'vue'



export default {

  setup() {

    onMounted(() => {
      console.log('onMounted!')

    })
    onUpdated(() => {
      console.log('updated!')
    })
    onUnmounted(() => {
      console.log('unmounted!')
    })
  },

}

</script>